$brand-1: #1d3557;
$brand-2: #457b9d;
$brand-white: #fff;

$highlight-color: #e8751e;
$heading-color: #002855;
$link-color: #004c97;
$text-color: #222222;
$text-heading-color: #333333;
$text-light-color: #c7c7c7;
$background-color: #eef2fe;
$background-color: #eef2fe;
$stroke-color: #eef2fe;
$box-border-color: #d9d9d9;

$alpha-01: 0.1;
$alpha-02: 0.2;
$alpha-03: 0.3;

$bk-small: 576px;
$bk-md: 768px;
$bk-lg: 992px;
$bk-xl: 1200px;

$header-font: "Arno Pro", cursive;
$paragraph-font: "Rubik", sans-serif;
$mono-font: "Roboto Mono", monospace;
